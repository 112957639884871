@font-face {
  font-family: 'LexieReadable';
  src: url('./LexieReadable-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'LexieReadable';
  src: url('./LexieReadable-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
