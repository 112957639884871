:root {
  --indigo-1000: #070d44;
  --indigo-900: #232669;
  --indigo-800: #273483;
  --indigo-700: #2b3b97;
  --indigo-600: #3f4a8a;
  --indigo-500: #4050b0;
  --indigo-400: #5969c9;
  --indigo-a400: #465ad6;
  --indigo-a200: #6175ec;
  --indigo-300: #6773bc;
  --indigo-200: #7d89d2;
  --indigo-100: #b1b2e0; /* #d3d4ed / #B1B2E0 40% / rgba(177, 178, 224, 0.4) */

  --navy-900: #2a2564;
  --navy-800: #313584;

  --deep-purple-600: #615bae;
  --deep-purple-300: #9079ee;
  --deep-purple-a400: #5c39e8;
  --deep-purple-a300: #7555f8;
  --deep-purple-a300-rgb: 117, 85, 248;
  --deep-purple-a200: #ad9eff;
  --deep-purple-a100: #cac0ff;
  --deep-purple-a50: #dcd6fd;
  --deep-purple-50: #efecff;

  --purple-800: #592f9e; /* TODO: change in design */
  --purple-700: #7448bb;
  --purple-a400: #b742fb;
  --purple-a300: #aa46e799; /* #AA46E7 60% / rgba(170, 70, 231, 0.6) */
  --purple-a200: #9b50c9b0; /* #9B50C9 69% / rgba(155, 80, 201, 0.69) */
  --purple-a100: #dda1ff;

  --grey-500: #a09fa9;
  --grey-200: #eaeaec;
  --grey-100: #f8f8f8; /* TODO: has no coresponding var in design */

  --white-transparent-10: rgba(255, 255, 255, 0.1);
  --white-transparent-50: rgba(255, 255, 255, 0.5);

  --black-transparent-10: rgba(0, 0, 0, 0.1);
  --black-transparent-30: rgba(0, 0, 0, 0.3);

  --gradient-progress-bar: linear-gradient(270deg, #c891ee 0%, #5e24ff 92.12%);

  --box-shadow: 0 6px 0px var(--black-transparent-10);

  /* TODO: check this vars in design and think about util */
  --transparent-circle-1: rgba(var(--deep-purple-a300-rgb), 0.6);
  --transparent-circle-2: rgba(var(--deep-purple-a300-rgb), 0.4);
  --transparent-circle-3: rgba(var(--deep-purple-a300-rgb), 0.2);
}
