.rc-tooltip {
  padding: 0 0 10px;
  background: none;
  opacity: 1;
}

.rc-tooltip-content {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
}

.rc-tooltip-inner {
  padding: 10px 50px;
  background-color: var(--purple-700);
  border: none;
}

.rc-tooltip-placement-top .rc-tooltip-arrow {
  bottom: 2px !important;
  width: 20px;
  height: 20px;
  margin: 0;
  transform: translateX(-50%) rotate(45deg);
  border: none;
  border-radius: 2px;
  background-color: var(--purple-700);
}
